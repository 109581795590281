import React, { useContext, useState, useEffect, Fragment } from 'react';
import { AppContext } from '../AppContext';
import {
    List, Divider, ListItem, FormControlLabel, Switch,
    ListItemText, Drawer, Typography, Box, FormLabel,
    FormControl, Radio, RadioGroup, InputLabel, Select, MenuItem, TextField, Button
} from '@material-ui/core';
import { Settings, Clear, Menu } from '@material-ui/icons';
import { Link } from "react-router-dom";
import Logo from "../images/big_logo_200x76.png"

export const Header = (props) => {
    const { page, albums, setPage, randomizeImages, setRandomizeImages,
        showThumbnails, setShowThumbnails, imageView, setImageView,
        thumbnailPosition, setThumbnailPosition,
        slideshowInterval, setSlideshowInterval, restoreDefautConfigs } = useContext(AppContext);
    const [menuShow, setShowMenu] = useState(false);

    return (
        <Fragment>
            <Box style={{
                position: 'fixed',
                width: '100%',
                backgroundColor: 'white',
                zIndex: 5,
                borderBottom: '1px solid #e6e6e6',
                height: 45
            }}>
                <Box style={{ clear: 'both' }}></Box>
                <Box style={{ float: 'left' }}>
                    <Link to={'/'} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Typography variant="h6" style={{ padding: '5px 5px 5px 20px', cursor: 'pointer' }} >
                            <img src={Logo} style={{height: 34}} />
                        </Typography>
                    </Link>
                </Box>
                <Box style={{ float: 'right' }}>
                    <Menu style={{ padding: '10px 20px', cursor: 'pointer' }} onClick={() => setShowMenu(true)} />
                </Box>
                <Box style={{ clear: 'both' }}></Box>
            </Box>
            <Divider />
            <Drawer anchor={'right'} open={menuShow} onClose={() => setShowMenu(false)}>
                <Box style={{ width: 250, padding: 20 }}>
                    <Clear style={{ marginLeft: -10, marginTop: -10, color: '#808080', marginBottom: 5, cursor: 'pointer' }} onClick={() => setShowMenu(false)} />
                    <Typography variant="h6" gutterBottom>Settings</Typography>

                    <FormControlLabel
                        control={
                            <Switch
                                checked={randomizeImages}
                                onChange={(e) => setRandomizeImages(e.target.checked)}
                                name="randomize-images"
                                color="primary"
                            />
                        }
                        label="Randomize Images"
                    />
                    <FormControl component="fieldset" style={{ marginTop: 10 }}>
                        <FormLabel component="legend">View</FormLabel>
                        <RadioGroup aria-label="view" name="view" value={imageView} style={{ marginLeft: 15 }}
                            onChange={(e) => setImageView(e.target.value)}>
                            <FormControlLabel value="slideshow" control={<Radio />} label="Slideshow" />
                            {
                                imageView === 'slideshow' && (
                                    <Box style={{ margin: '0px 0 5px 30px' }}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={showThumbnails}
                                                    onChange={(e) => setShowThumbnails(e.target.checked)}
                                                    name="show-thumbnails"
                                                    color="primary"
                                                />
                                            }
                                            label="Show Thumbnails"
                                            style={{ margin: '0 0 0 -15px' }}
                                        />
                                        {
                                            showThumbnails === true && (
                                                <FormControl style={{ width: '100%', margin: '10px 0' }}>
                                                    <InputLabel id="thumbnail-position-label">Thumbnail Position</InputLabel>
                                                    <Select
                                                        labelId="thumbnail-position-label"
                                                        id="thumbnail-position-select"
                                                        value={thumbnailPosition}
                                                        onChange={(e) => setThumbnailPosition(e.target.value)}
                                                    >
                                                        <MenuItem value={'bottom'}>Bottom</MenuItem>
                                                        <MenuItem value={'left'}>Left</MenuItem>
                                                        <MenuItem value={'right'}>Right</MenuItem>
                                                        <MenuItem value={'top'}>Top</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            )
                                        }

                                        <FormControl style={{ width: '100%', margin: '10px 0' }}>
                                            <InputLabel id="slideshow-interval-label">Slideshow Interval</InputLabel>
                                            <Select
                                                labelId="slideshow-interval-label"
                                                id="slideshow-interval-select"
                                                value={slideshowInterval}
                                                onChange={(e) => setSlideshowInterval(e.target.value)}
                                            >
                                                <MenuItem value="2000">2 seconds</MenuItem>
                                                <MenuItem value="4000">4 seconds</MenuItem>
                                                <MenuItem value="6000">6 seconds</MenuItem>
                                                <MenuItem value="8000">8 seconds</MenuItem>
                                                <MenuItem value="10000">10 seconds</MenuItem>
                                                <MenuItem value="12000">12 seconds</MenuItem>
                                                <MenuItem value="15000">15 seconds</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                )
                            }
                            <FormControlLabel value="grid" control={<Radio />} label="Grid" />
                            <FormControlLabel value="list" control={<Radio />} label="List" />
                        </RadioGroup>
                    </FormControl>

                    <Box style={{ textAlign: 'right' }}>
                        <Button color="primary" onClick={restoreDefautConfigs}>Restore Defaults</Button>
                    </Box>
                    <Divider style={{ marginTop: 10, marginBottom: 10, paddingLeft: 40, paddingRight: 40 }} />

                    <Link to={'/'} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Typography variant="h6" gutterBottom>eBooks</Typography>
                    </Link>
                    {
                        Object.keys(albums).map(albumID => (
                            <Link to={'/' + albumID} style={{ textDecoration: 'none', color: 'inherit' }}>
                                <ListItem button>
                                    <ListItemText primary={albums[albumID].title} onClick={() => { setShowMenu(false) }} />
                                </ListItem>
                            </Link>
                        ))
                    }
                </Box>
            </Drawer>
        </Fragment>
    )
}
import React, { useContext, Fragment } from 'react';
import { AppContext } from '../AppContext';
import { Typography, Container, Grid, Paper, Box } from '@material-ui/core';
import { Link } from "react-router-dom";

export const Home = (props) => {
    const { albums, imageBaseURL } = useContext(AppContext)

    return (
        <Fragment>
            <Container maxWidth="md" style={{ marginTop: 75 }}>
                <Grid container spacing={3}>
                    {
                        Object.keys(albums).map(albumID => (
                            <Grid item xs={6} sm={6} md={4} style={{ cursor: 'pointer' }}>
                                <Link to={'/' + albumID} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <Paper square elevation={2} style={{ padding: 20 }}>
                                        <Typography variant="body2" style={{
                                            textAlign: 'left',
                                            color: '#666666',
                                            paddingBottom: 10,
                                            fontWeight: 'bold',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}>
                                            {albums[albumID].title}
                                        </Typography>
                                        <Box
                                            style={{
                                                backgroundImage: `url('${imageBaseURL + albums[albumID].images[0].url}')`,
                                                height: 160,
                                                backgroundSize: 'contain',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundOrigin: 'content-box'
                                            }}>

                                        </Box>
                                    </Paper>
                                </Link>
                            </Grid>
                        ))
                    }

                </Grid>
            </Container>
        </Fragment>
    )
}
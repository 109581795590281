import React from 'react';
import './App.css';
import { AppContextProvider } from './AppContext';
import { Header } from './components/Header';
import {BrowserRouter as Router} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <AppContextProvider>
          <Header />
        </AppContextProvider>
      </Router>
    </div>
  );
}

export default App;

import React, { useContext, useState, useEffect, Fragment, useRef } from 'react';
import { AppContext } from '../AppContext';
import {Box, GridList, GridListTile, GridListTileBar, ListSubheader, IconButton, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import {Info} from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
    gridRoot: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
  }));

export const Album = (props) => {
    const classes = useStyles();
    const [firstImageLoaded, setFirstImageLoaded] = useState(false);
    const {page, setPage, albums, images, showThumbnails, slideshowInterval, thumbnailPosition, imageView } = useContext(AppContext);
    const imgGalleryRef = useRef();
    const isPlaying = useRef(false);

    useEffect(() => {
        setPage(props.albumID);
    }, [props.albumID]);

    useEffect(() => {
        if (imgGalleryRef && imgGalleryRef.current) {
            if (isPlaying.current) {
                imgGalleryRef.current.pause();
                imgGalleryRef.current.play();
            }
        }
    }, [slideshowInterval]);

    return (
        <Box style={{ marginTop: 65 }}>
            <Box style={{display: 'none'}}>
                {
                    images.length > 0 && 
                    <img
                        src={images[0].original}
                        onLoad={() => setFirstImageLoaded(true)}
                        onError={() => setFirstImageLoaded(true)}
                    />
                }
            </Box>
            <Box style={{margin: 20}}>
                {
                    !firstImageLoaded && 
                    <Fragment>
                        {
                            [...new Array(25)].map(i => <Skeleton variant="text" style={{margin: '10px auto', width: '50%'}} />)
                        }
                    </Fragment>
                }
                {
                    imageView === 'slideshow' && (
                        <Fragment>
                            <Typography variant={'h6'} style={{color: '#666666', marginBottom: 20}}>
                                {albums.hasOwnProperty(page) ? albums[page].title : ''}
                            </Typography>
                            <Box style={{height: 600, maxWidth: 600, margin: '0 auto'}}>
                            {
                                images.length > 0 && firstImageLoaded
                                && <ImageGallery ref={imgGalleryRef}
                                            items={images} 
                                            //onSlide={(i) => console.log(i)} 
                                            //onBeforeSlide={(i) => console.log(i)}
                                            //onScreenChange={(val) => setFullScreen(val)}
                                            onPlay={(e) => {isPlaying.current = true}}
                                            onPause={(e) => {isPlaying.current = false}}
                                            showThumbnails={showThumbnails}
                                            showIndex={true} 
                                            lazyLoad={false} 
                                            thumbnailPosition={thumbnailPosition} 
                                            slideDuration={450} 
                                            slideInterval={slideshowInterval} />
                            }
                            </Box>
                        </Fragment>
                    )
                }
                {
                    (imageView === 'grid' || imageView === 'list') && (
                        <Fragment>
                            <Typography variant={'h6'} style={{color: '#666666', marginBottom: 20}}>
                                {albums.hasOwnProperty(page) ? albums[page].title : ''}
                            </Typography>
                            <div className={classes.gridRoot}>
                                <GridList 
                                    cellHeight={imageView === 'grid' ? 200 : 'auto'} 
                                    style={{width: (imageView === 'grid' ? 400 : 400), height: 'auto'}} 
                                    cols={imageView === 'grid' ? 2 : 1}>
                                {images.length > 0 && firstImageLoaded && images.map((tile) => (
                                    <GridListTile key={tile.img}>
                                    <img src={tile.original} alt={tile.description} 
                                        style={{width: (imageView === 'grid' ? '200px' : '100%'), height: (imageView === 'grid' ? 'inherit' : 'auto')}} />
                                    <GridListTileBar style={{right: 'auto', height: 24, bottom: (imageView === 'grid' ? '0px' : '4px')}}
                                        title={<span style={{fontSize: 14}}>{tile.description}</span>}
                                    />
                                    </GridListTile>
                                ))}
                                </GridList>
                            </div>
                        </Fragment>
                    )
                }
            </Box>
        </Box>
    )
}
import React, { createContext, useState, useEffect } from 'react';
import aquatic_sea_animals from './data/aquatic_sea_animals.json';
import birds from './data/birds.json';
import colors from './data/colors.json';
import numbers from './data/numbers.json';
import alphabets_capital from './data/capital_letters.json';
import domestic_farm_animals from './data/domestic_pet_animals.json';
import amphibians_insects_reptiles from './data/amphibians_insects_reptiles.json';
import shapes from './data/shapes.json';
import alphabets_small from './data/small_letters.json';
import solar_system from './data/solar_system.json';
import transport from './data/transport.json';
import wild_animals from './data/wild_animals.json';
import fruits from './data/fruits.json';
import {
    Switch,
    Route, 
    useLocation
} from "react-router-dom";
import { Album } from './components/Album';
import { Home } from './components/Home';
export const AppContext = createContext();

export const AppContextProvider = (props) => {
    const location = useLocation();
    const [page, setPage] = useState('home');
    const [albums, setAlbums] = useState({});
    const [images, setImages] = useState([]);
    const [randomizeImages, setRandomizeImages] = useState(false);
    const [imageView, setImageView] = useState('slideshow');
    const [showThumbnails, setShowThumbnails] = useState(true);
    const [slideshowInterval, setSlideshowInterval] = useState(4000);
    const [thumbnailPosition, setThumbnailPosition] = useState('bottom');
    const imageBaseURL = 'https://s3.amazonaws.com/www.thetoddleruniversity.com/images';

    const getAlbums = async () => {
        let data = {
            alphabets_capital,
            alphabets_small,
            amphibians_insects_reptiles,
            aquatic_sea_animals,
            birds,
            colors,
            domestic_farm_animals,
            fruits,
            numbers,
            shapes,
            solar_system,
            transport,
            wild_animals
        };
        setAlbums(data);
    };

    const randomInteger = (min, max) => {
        // min & max included
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const randomizeArr = (arr) => {
        for (let i = 0; i < arr.length; i++) {
            let r = randomInteger(i, arr.length - 1);
            let curr = arr[i];
            arr[i] = arr[r];
            arr[r] = curr;
        }
        return arr;
    };

    const createImageArr = (id) => {
        let album = albums[id];
        if (!album) {
            return [];
        }
        let imageArr = [...Array(album.images.length).keys()];
        if (!randomizeImages) {
            return imageArr;
        }
        return randomizeArr(imageArr);
    };

    useEffect(() => {
        getAlbums();
        let configs = localStorage.getItem('configs');
        if (configs) {
            configs = JSON.parse(configs);
            const { randomizeImages: ri, imageView: iv, showThumbnails: st, slideshowInterval: si, thumbnailPosition: tp } = configs;
            setRandomizeImages(ri);
            setImageView(iv);
            setShowThumbnails(st);
            setSlideshowInterval(si);
            setThumbnailPosition(tp);
        }
        setTimeout(window.scrollTo(0, 0), 300);
    }, []);

    useEffect(() => {
        setTimeout(window.scrollTo(0, 0), 300);
    }, [location]);

    useEffect(() => {
        let configs = { randomizeImages, imageView, showThumbnails, slideshowInterval, thumbnailPosition };
        localStorage.setItem('configs', JSON.stringify(configs));
    }, [randomizeImages, imageView, showThumbnails, slideshowInterval, thumbnailPosition]);

    useEffect(() => {
        //console.log(page)
        if (page === 'home' || Object.keys(albums).length === 0 || !albums.hasOwnProperty(page)) {
            return;
        }
        let imgArr = createImageArr(page);
        let img_list = albums[page].images;
        img_list.sort(function(a, b) {
            if (a.title < b.title) return -1;
            if (a.title > b.title) return 1;
            return 0;
        });
        let imgs = [];
        imgArr.map(i => {
            let obj = img_list[i];
            imgs.push({
                original: imageBaseURL + obj.url,
                thumbnail: imageBaseURL + obj.url,
                //originalTitle: obj.title, 
                description: obj.title,
                //thumbnailLabel: obj.title
            });
        });
        setImages(imgs);
    }, [page, albums, randomizeImages]);

    const restoreDefautConfigs = () => {
        setRandomizeImages(false);
        setImageView('slideshow');
        setShowThumbnails(true);
        setSlideshowInterval(4000);
        setThumbnailPosition('bottom');
    }

    return (
        <AppContext.Provider value={{
            albums, images,
            page, setPage,
            randomizeImages, setRandomizeImages,
            showThumbnails, setShowThumbnails,
            imageView, setImageView,
            thumbnailPosition, setThumbnailPosition,
            slideshowInterval, setSlideshowInterval,
            restoreDefautConfigs,
            imageBaseURL
        }}>
            {props.children}
            <Switch>
                {
                    Object.keys(albums).map(albumID => (
                        <Route path={'/' + albumID} strict>
                            <Album albumID={albumID} />
                        </Route>
                    ))
                }
                <Route path='/' strict>
                    <Home />
                </Route>
            </Switch>
        </AppContext.Provider>
    )

}